import classNames from "classnames"
import React from "react"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"

type MarkdownProps = React.ComponentProps<typeof ReactMarkdown>

export const Markdown: React.FC<MarkdownProps> = ({
  children,
  className,
  ...rest
}) => (
  <ReactMarkdown
    linkTarget="_blank"
    allowedElements={[`p`, `br`, `a`, `strong`, `i`, `em`, `u`]}
    rehypePlugins={[rehypeRaw]}
    components={{
      u: ({ node: _node, ...props }) => (
        <u style={{ textDecoration: `underline` }} {...props} />
      ),
    }}
    className={classNames(`markdown`, className)}
    {...rest}
  >
    {children}
  </ReactMarkdown>
)
