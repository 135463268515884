import * as React from "react"
import { PageProps } from "gatsby"
import {
  CharitiesQuery,
  CharitiesWeSupportJsonCharitiesList,
  CharitiesWeSupportJsonFeaturedCharitiesCarousel,
} from "@/../gatsby-graphql"

import Layout from "@components/Layout"
import SEO from "@components/SEO"
import { FeaturedCharitiesCarousel } from "@components/FeaturedCharitiesCarousel"
import { CharitiesPageHeader } from "@components/CharitiesPageHeader"
import { CharitiesList } from "@components/CharitiesList"
import { IPreviewContext } from "@context/previewContext"
import {
  getSEODescription,
  getSEOImage,
} from "../utils/charitiesWeSupportUtils"
import ContextWrapper from "./ContextWrapper"

type Props = PageProps<CharitiesQuery> & IPreviewContext

function CharitiesPage({ data, location, ...rest }: Props) {
  const {
    pageHeader,
    featuredCharitiesCarousel,
    charitiesList,
    fields: { slug },
  } = data.charitiesWeSupportJson
  return (
    <ContextWrapper {...rest}>
      <Layout location={location}>
        {pageHeader && (
          <>
            <SEO
              title="Charities"
              description={getSEODescription(pageHeader)}
              slug={slug}
              image={getSEOImage(featuredCharitiesCarousel)}
            />
            <CharitiesPageHeader pageHeader={pageHeader} />
          </>
        )}
        {featuredCharitiesCarousel && (
          <FeaturedCharitiesCarousel
            carouselItems={
              featuredCharitiesCarousel as CharitiesWeSupportJsonFeaturedCharitiesCarousel[]
            }
          />
        )}
        {charitiesList && charitiesList.values && (
          <CharitiesList
            charitiesList={charitiesList as CharitiesWeSupportJsonCharitiesList}
          />
        )}
      </Layout>
    </ContextWrapper>
  )
}

export default CharitiesPage
