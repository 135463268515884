import * as React from "react"
import { PageProps } from "gatsby"
import classnames from "classnames"

import Layout from "@components/Layout"
import SEO from "@components/SEO"
import { Hero } from "@components/Hero"
import { GrantsExclusions } from "@components/GrantsExclusions"
import { GrantsCriteria } from "@components/GrantsCriteria"
import { GrantsHowToBeConsidered } from "@components/GrantsHowToBeConsidered"
import { GrantsCharitiesList } from "@components/GrantsCharitiesList"
import {
  GrantsQuery,
  OurGrantProcessJsonCharitiesList,
  OurGrantProcessJsonCriteria,
  OurGrantProcessJsonExclusions,
} from "@/../gatsby-graphql"
import { IPreviewContext } from "@context/previewContext"
import { getSEODescription, getSEOImage } from "../utils/ourGrantProcessUtils"

import { criteriaShadow } from "./our-grant-process.module.scss"
import ContextWrapper from "./ContextWrapper"

type Props = PageProps<GrantsQuery> & IPreviewContext

function GrantsPage({ data, location, ...rest }: Props) {
  const {
    charitiesList,
    criteria,
    hero,
    howToBeConsidered,
    exclusions,
    fields: { slug },
  } = data.ourGrantProcessJson

  return (
    <ContextWrapper {...rest}>
      <Layout location={location}>
        <SEO
          title="Grants"
          description={getSEODescription(charitiesList)}
          image={getSEOImage(hero)}
          slug={slug}
        />
        {hero && <Hero {...hero} />}
        {charitiesList && (
          <GrantsCharitiesList
            charitiesList={charitiesList as OurGrantProcessJsonCharitiesList}
          />
        )}
        {(criteria || howToBeConsidered) && (
          <div
            className={classnames(
              criteriaShadow,
              `bg-citrine-accent pb-10`,
              `md:pb-16`,
              `lg:pb20`
            )}
          >
            {criteria && (
              <GrantsCriteria
                criteria={criteria as OurGrantProcessJsonCriteria}
              />
            )}
            {howToBeConsidered && (
              <GrantsHowToBeConsidered howToBeConsidered={howToBeConsidered} />
            )}
          </div>
        )}
        {exclusions && (
          <GrantsExclusions
            exclusions={exclusions as OurGrantProcessJsonExclusions}
          />
        )}
      </Layout>
    </ContextWrapper>
  )
}

export default GrantsPage
