import classnames from "classnames"
import React, { ReactElement } from "react"
import { Image } from "../../utils/image"
import CustomGatsbyImage from "../CustomGatsbyImage/CustomGatsbyImage"
import { Markdown } from "../Markdown"

interface Props {
  preTitle?: string
  preDescription?: string
  title?: string
  description?: string
  image?: Image
  imageClassName?: string
}

function Hero({
  preDescription,
  preTitle,
  image,
  title,
  description,
  imageClassName,
}: Props): ReactElement {
  return (
    <div className={classnames(`relative z-0 w-full flex justify-center`)}>
      <CustomGatsbyImage
        alt={image.alt}
        className={classnames(`w-full min-w-140 min-h-2/5`, imageClassName)}
        image={image.src}
      />
      <div
        className={classnames(
          `h-full w-full absolute top-0 left-0 right-0 bottom-0`,
          {
            "bg-citrine-background-hero": title || description,
          }
        )}
      >
        <div
          className={classnames([
            `absolute text-white flex flex-col justify-center items-center w-full`,
            {
              "top-2/4": !preTitle && !preDescription,
              "top-1/3": !!preTitle || !!preDescription,
            },
          ])}
        >
          <div
            className={classnames(
              `flex flex-col justify-center items-center w-full`,
              { "mb-4": !!preTitle || !!preDescription }
            )}
          >
            {preTitle && (
              <h1
                className={classnames(
                  `heading__2 text-center text-white text-2xl px-2`,
                  `md:text-3xl md:px-1/12`,
                  `lg:text-5xl lg:w-full`,
                  `xl:text-6xl`
                )}
              >
                {preTitle}
              </h1>
            )}
            {preDescription && (
              <Markdown
                className={classnames(
                  `font-bold max-w-5/6 text-center mt-2 mb-4 text-2xs`,
                  `sm:text-sm`,
                  `lg:text-base lg:mt-3 lg:max-w-4/6`,
                  `xl:text-lg xl:mt-4`,
                  `2xl:mx-10`
                )}
              >
                {preDescription}
              </Markdown>
            )}
          </div>
          {title && (
            <h1
              className={classnames(
                `heading__2 text-center text-white text-2xl px-2 `,
                `md:text-3xl md:px-1/12`,
                `lg:text-5xl lg:w-full`,
                `xl:text-6xl`
              )}
            >
              {title}
            </h1>
          )}
          {description && (
            <Markdown
              className={classnames(
                `font-bold max-w-5/6 text-center mt-2 text-2xs`,
                `sm:text-sm`,
                `lg:text-base lg:mt-3 lg:max-w-4/6`,
                `xl:text-lg xl:mt-4`,
                `2xl:mx-10`
              )}
            >
              {description}
            </Markdown>
          )}
        </div>
      </div>
    </div>
  )
}

export default Hero
