import classnames from "classnames"
import React, { ReactElement } from "react"
import { Image } from "../../utils/image"
import CustomGatsbyImage from "../CustomGatsbyImage/CustomGatsbyImage"
import { Markdown } from "../Markdown"
import { descriptionMarkdownStyling } from "./descriptionStyles.module.scss"

interface Props {
  description: string
  headshotImage: Image
}

export function Description({
  description,
  headshotImage,
}: Props): ReactElement {
  return (
    <div>
      <CustomGatsbyImage
        alt={headshotImage?.alt}
        image={headshotImage?.src}
        className={classnames(
          `mx-auto mb-5 max-w-60 block`,
          `sm:float-left sm:mb-1 sm:ml-15 sm:min-w-28 sm:w-3/10 sm:mr-5 sm:inline-block`,
          `md:ml-16`,
          `lg:ml-24 lg:mt-1`,
          `xl:ml-28 xl:max-w-84 xl:w-1/3`,
          `2xl:ml-36`
        )}
      />
      <Markdown
        className={classnames(
          descriptionMarkdownStyling,
          `text-xs mx-5 float-none`,
          `sm:mx-15 sm:leading-relaxed`,
          `md:mx-16 md:leading-5 sm:text-sm`,
          `lg:mx-24 lg:leading-6 lg:text-base`,
          `xl:text-lg xl:mx-28 xl:leading-8`,
          `2xl:text-xl 2xl:mx-36 2xl:leading-10`
        )}
      >
        {description}
      </Markdown>
    </div>
  )
}
