import classnames from "classnames"
import React from "react"
import { ContactUsJsonGetInTouch } from "@/../gatsby-graphql"
import { Markdown } from "../Markdown"

const basicTextClasses = [
  `text-citrine-gray-medium text-2xs`,
  `md:text-xs`,
  `lg:text-sm`,
  `xl:text-base`,
  `2xl:text-lg`,
]

export function ContactUsGetInTouch({
  description,
  note,
  title,
  warning,
}: ContactUsJsonGetInTouch) {
  return (
    <div
      className={classnames(
        `flex flex-col mb-4`,
        `sm:mb-0 sm:w-4/10`,
        `2xl:mb-0`
      )}
    >
      {title && (
        <p
          className={classnames(
            `text-citrine-primary-light font-extrabold text-2xl mb-1`,
            `md:text-3xl md:mb-1.5`,
            `lg:text-5xl`
          )}
        >
          {title}
        </p>
      )}
      {description && (
        <p
          className={classnames(
            ...basicTextClasses,
            `font-semibold text-left mb-4 max-w-10/12`
          )}
        >
          {description}
        </p>
      )}
      <div
        className={classnames(
          `border-4 p-3 flex flex-col text-center border-citrine-primary-medium`,
          `xl:border-8`
        )}
      >
        {note && (
          <Markdown
            className={classnames(...basicTextClasses, `font-semibold mb-3`)}
          >
            {note}
          </Markdown>
        )}
        {warning && (
          <p className={classnames(...basicTextClasses, `font-extrabold`)}>
            {warning}
          </p>
        )}
      </div>
    </div>
  )
}
