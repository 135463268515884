import React, { ReactElement } from "react"
import classnames from "classnames"
import { CharitiesWeSupportJsonFeaturedCharitiesCarousel } from "@/../gatsby-graphql"
import CustomGatsbyImage from "../CustomGatsbyImage/CustomGatsbyImage"
import { Markdown } from "../Markdown"
import { charityItem, charityImage } from "./styles.module.scss"

type Props = CharitiesWeSupportJsonFeaturedCharitiesCarousel

export function FeaturedCharitiesCarouselItem({
  backgroundImage,
  description,
  icon,
  quote,
}: Props): ReactElement {
  return (
    <div className="relative max-h-home-hero-carousel flex justify-center items-start w-screen max-w-screen overflow-hidden lg:w-full h-full">
      <div className="relative h-full w-full">
        <CustomGatsbyImage
          image={backgroundImage.src}
          alt={backgroundImage.alt}
          className="block min-w-140 h-full w-full relative -z-10"
          draggable={false}
          imgStyle={{ objectPosition: `top` }}
        />
        <div className="absolute top-0 left-0 right-0 bottom-0 backdrop-filter backdrop-brightness-90" />
      </div>
      <div
        className={classnames(
          charityItem,
          `absolute w-full h-full bg-citrine-background-hero text-white flex flex-col text-center content-center items-center justify-center`
        )}
      >
        <div className="w-full h-full container flex flex-col text-center content-center items-center justify-center">
          <CustomGatsbyImage
            className={classnames(charityImage)}
            image={icon.src}
            alt={icon.alt}
          />
          <h2
            className={classnames(
              `w-full mb-2 text-xs italic font-semibold px-2 flex justify-center`,
              `sm:text-lg sm:px-0`,
              `md:text-xl`,
              `lg:text-3xl lg:w-full lg:mb-5`,
              `xl:text-4xl xl:mb-6`
            )}
          >
            <Markdown unwrapDisallowed className="w-11/12 2xl:w-full">
              {quote}
            </Markdown>
          </h2>
          <h3
            className={classnames(
              `font-medium text-2xs mb-4`,
              `sm:text-2xs sm:px-1/12`,
              `md:text-sm`,
              `lg:text-base lg:mb-5`,
              `xl:text-xl xl:mb-7`,
              `2xl:text-2xl 2xl:mb-9`
            )}
          >
            {description}
          </h3>
        </div>
      </div>
    </div>
  )
}
