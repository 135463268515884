import React, { ReactElement } from "react"
import classNames from "classnames"
import { RootQuery } from "@/../gatsby-graphql"
import { Carousel } from "../Carousel"
import CustomGatsbyImage from "../CustomGatsbyImage/CustomGatsbyImage"
import HeroDot from "../Carousel/HeroDot"
import { Markdown } from "../Markdown"

interface Props {
  items: RootQuery["homeJson"]["carouselImages"]
}

function HeroCarousel({ items }: Props): ReactElement {
  return (
    <Carousel pauseOnHover customDot={<HeroDot />}>
      {items.map(({ image, text, textLocation }, i) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={`${text}-${i}`}
          className="relative max-h-home-hero-carousel flex justify-center items-start w-screen max-w-screen overflow-hidden lg:w-full"
        >
          <CustomGatsbyImage
            image={image.src}
            alt={image.alt}
            className="block min-w-140 w-full relative -z-10"
            draggable={false}
            imgStyle={{ objectPosition: `top` }}
          />
          <h2
            className={classNames(
              `heading__2 absolute text-center w-full text-white text-base px-2 flex justify-center container`,
              {
                "top-1/6": textLocation === `top`,
                "bottom-1/6": textLocation === `bottom`,
              },
              `sm:text-2xl`,
              `md:text-3xl md:px-1/12`,
              `lg:text-4xl lg:w-full`,
              `xl:text-5xl xl:px-0`,
              `2xl:text-6xl`
            )}
          >
            <Markdown
              unwrapDisallowed
              className="w-11/12 text-center text-shadow xl:w-full"
            >
              {text}
            </Markdown>
          </h2>
        </div>
      ))}
    </Carousel>
  )
}

export default HeroCarousel
